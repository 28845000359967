<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="text-center">
          <h4>Register</h4>
          <b-form @submit.prevent="register">
            <label for="name">Name</label>
            <div>
              <input id="name" type="text" v-model="name" required autofocus>
            </div>

            <label for="email">E-Mail Address</label>
            <div>
              <input id="email" type="email" v-model="email" required>
            </div>

            <label for="password">Password</label>
            <div>
              <input id="password" type="password" v-model="password" required>
            </div>

            <label for="password-confirm">Confirm Password</label>
            <div>
              <input id="password-confirm" type="password" v-model="password_confirmation" required>
            </div>

            <br>

            <div>
              <b-button type="submit">Register</b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      is_admin: null
    }
  },
  methods: {
    register: function () {
      let data = {
        name: this.name,
        email: this.email,
        password: this.password,
        is_admin: this.is_admin
      }
      this.$store.dispatch('register', data)
          .then(() => this.$router.push('/'))
          .catch(err => console.log(err))
    }
  }
}
</script>

<style scoped>

</style>
