<template>
  <div>
    <b-container>
      <div class="row">
      <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <div class="views-field views-field-title">
          <p class="field-content card-title">2-1-1 Arizona</p>
        </div>
        <div class="views-field views-field-body">
          <div class="field-content">
            <p><strong>2-1-1 Arizona</strong> is a hotline and web-based directory of services for a variety of
              different assistance programs specific to your community.</p>
          </div>
        </div>
        <div class="views-field views-field-field-contact-info">
          <div class="field-content">
            <p>2-1-1 or (877) 211-8661</p>
          </div>
        </div>
        <div class="views-field views-field-field-resource-link">
          <div class="field-content btn btn-outline-primary"><a href="https://211arizona.org"
                                                                class="content-resource ext extlink" rel="nofollow"
                                                                target="_blank">Find help<span class="ext"><span
              class="element-invisible"> (link is external)</span></span></a></div>
        </div>
      </div>
      <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <div class="views-field views-field-title">
          <p class="field-content card-title">Arizona Coalition for Military Families</p>
        </div>
        <div class="views-field views-field-body">
          <div class="field-content">
            <p>The <strong>Arizona Coalition for Military Families</strong> builds on Arizona's statewide capacity to
              care for, serve and support all service members, veterans, their families and communities.</p>
          </div>
        </div>
        <div class="views-field views-field-field-contact-info">
          <div class="field-content">
            <p>(602) 753-8802</p>
          </div>
        </div>
        <div class="views-field views-field-field-resource-link">
          <div class="field-content btn btn-outline-primary"><a href="https://www.Arizonacoalition.org"
                                                                class="content-resource ext extlink" rel="nofollow"
                                                                target="_blank">Learn more<span class="ext"><span
              class="element-invisible"> (link is external)</span></span></a></div>
        </div>
      </div>
      <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
        <div class="views-field views-field-title">
          <p class="field-content card-title">Arizona Department of Child Safety</p>
        </div>
        <div class="views-field views-field-body">
          <div class="field-content">
            <p>The <strong>Arizona Department of Child Safety</strong> provides services and resouces to strengthen
              families and ensure the safety, permanency and well-being of children</p>
          </div>
        </div>
        <div class="views-field views-field-field-contact-info">
          <div class="field-content">
            <p>AZ Child Abuse Hotline:<br>(888) 767-2445</p>
            <p>DCS Family Advocate:<br>(877) 527-0765</p>
            <p>DCS Caregiver Warmline:<br>(877)543-7633</p>
          </div>
        </div>
        <div class="views-field views-field-field-resource-link">
          <div class="field-content btn btn-outline-primary"><a href="https://dcs.az.gov/" class="content-resource"
                                                                rel="nofollow">Find resources</a></div>
        </div>
      </div>
    </div>
      <div class="row">
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Department of Economic Security, Supplemental Nutrition Assistance
              Program</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The DES <strong>Family Assistance Administration</strong> determines eligibility for Nutrition
                Assistance, a monthly benefits program to help you and your family purchase nutritious foods.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(855) 432-7587</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://des.az.gov/na" class="content-resource"
                                                                  rel="nofollow">Find out more</a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Department of Education, Health and Nutrition Services</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Arizona Department of Education, Health and Nutrition Services</strong> provides nutritious
                meals to children in child care, school and after school care, at no or minimal cost.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(800) 352-4558, Option 6</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.azed.gov/hns/"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find help<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Department of Health Services, Arizona WIC Program</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The Arizona Supplemental Nutrition Program for <strong>Women, Infants and Children</strong> (WIC)
                provides nutrition education and breastfeeding support services, supplemental nutrition foods and
                referrals to health and social services.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(800) 252-5942</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.azwic.gov"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find help<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Department of Health Services, Farmers Market Nutrition
              Program</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Farmers Market Nutrition Program</strong> provides checks to eligible women, children and
                seniors to purchase Arizona-grown produce at approved farmers markets.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(602) 542-1886<br><a href="mailto:azfmnp@azdhs.gov">azfmnp@azdhs.gov</a></p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a
                href="https://azdhs.gov/prevention/azwic/farmers-markets/index.php" class="content-resource ext extlink"
                rel="nofollow" target="_blank">Learn more<span class="ext"><span class="element-invisible"> (link is external)</span></span></a>
            </div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Department of Veterans' Services</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Arizona Department of Veterans' Services</strong> helps veterans connect with their VA
                benefits and other organizations and services available to them around the state.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(602) 255-3373</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://dvs.az.gov" class="content-resource"
                                                                  rel="nofollow">Find help</a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Health Care Cost Containment System (AHCCCS)</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Arizona Health Care Cost Containment System</strong> (AHCCCS) is Arizona's Medicaid agency,
                offering healthcare programs to serve Arizona residents.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(855) 432-7587</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://azahcccs.gov" class="content-resource"
                                                                  rel="nofollow">Find out more</a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Helping Hands</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p><strong>Arizona Helping Hands</strong> provides resources to foster care families to help meet the basic
                essential needs of children in foster care.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(480) 889-0604</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://azhelpinghands.org/"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find help<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Arizona Self Help</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p><strong>Arizona Self Help</strong> is a free and easy way to find out if your family may be able to get
                help from 40 different assistance programs in Arizona.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(602) 604-0640<br><a href="mailto:ArizonaSelfHelp@gmail.com">ArizonaSelfHelp@gmail.com</a></p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="http://arizonaselfhelp.org/"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find resources<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">ARIZONA@WORK</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p><strong>ARIZONA<span>@</span>WORK</strong> plays an integral role in improving Arizona's workforce by
                assisting individuals who are unemployed and underemployed prepare for and obtain gainful employment.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content"></div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.arizonaatwork.com/"
                                                                  class="content-resource" rel="nofollow">Find out
              more</a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Association of Arizona Food Banks</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Association of Arizona Food Banks</strong> represents regional food banks and pantries around
                the state, and can help you find the nearest emergency food option to you.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(800) 445-1914</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.azfoodbanks.org"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find a food bank<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">AZ Health Zone</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p><strong>AZ Health Zone</strong> provides your family with opportunities and resources to stay healthy and
                active.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content"></div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.azhealthzone.org/"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Learn more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">AZ529 Education Savings Plan</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>AZ529 is a state-sponsored savings plan designed to provide a parent, grandparent or future student an
                opportunity to save for educational expenses in a tax-deferred manner.</p>
              <p>602-542-7529</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content"></div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://az529.gov/"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Learn more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Breastfeeding 24-Hour Hotline</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The Breastfeeding Hotline is a free, round-the-clock hotline that will answer your pregnancy and
                breastfeeding questions</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(800) 833-4642</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a
                href="https://www.azdhs.gov/prevention/nutrition-physical-activity/breastfeeding/index.php#24-hr-bf-hotline"
                class="content-resource ext extlink" rel="nofollow" target="_blank">Learn more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Crisis Response Network</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Crisis Response Network</strong> reviews all applications for Serious Mental Illness (SMI)
                determination and services in Arizona.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(855) 832-2866</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.crisisnetwork.org"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Learn more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Crisis Text Line</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Crisis Text Line</strong> provides free, 24-hour support for those in crisis. Text HOME to
                741-741 to speak with a trained crisis counselor.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>Text HOME to 741-741</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.crisistextline.org"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find out more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Double Up Food Bucks Arizona</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Double Up Food Bucks</strong> program doubles the value of Nutrition Assistance at farmers
                markets, community support agriculture farms, food banks and certain grocers.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>1-833-8-DOUBLE<br>(1-833-836-8253)</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.doubleupaz.org"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Learn more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">EveryoneOn</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>EveryoneOn online directory</strong> lists options for low-cost internet and computers based
                on your zip code.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>Contact your local telephone, internet or utility services for more information about low-cost options
                for eligible families.</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://everyoneon.org"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Learn more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Federal Health Care Marketplace</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Federal Healthcare Marketplace</strong> is an online resource and 24-hour hotline to provide
                information and help you apply for health care insurance through the federal marketplace.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>(800) 318-2596</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://healthcare.gov"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find resources<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">Summer Food Service Program</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>Summer Food Service Program</strong> (SFSP) ensures low-income children, ages 18 and younger,
                receive nutritious meals when school is not in session.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>Text FOOD to 877-877</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://www.azhealthzone.org/summerfood"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Learn more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">United States Department of Housing and Urban Development</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>U.S. Department of Housing and Urban Development</strong> (HUD) works with local public
                housing authorities to provide public housing, housing choice vouchers (Section 8) and reduced rents to
                low-income tenants.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content"></div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://hud.gov/states/arizona/renting"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find help<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
        <div class="card card-body card-title col-xs-12 col-sm-6 col-md-4 col-lg-4">
          <div class="views-field views-field-title">
            <p class="field-content card-title">United States Department of Veterans Affairs</p>
          </div>
          <div class="views-field views-field-body">
            <div class="field-content">
              <p>The <strong>U.S. Department of Veterans Affairs</strong> provides those who've served with a large
                variety of services and benefits.</p>
            </div>
          </div>
          <div class="views-field views-field-field-contact-info">
            <div class="field-content">
              <p>844-698-2311</p>
            </div>
          </div>
          <div class="views-field views-field-field-resource-link">
            <div class="field-content btn btn-outline-primary"><a href="https://va.gov"
                                                                  class="content-resource ext extlink" rel="nofollow"
                                                                  target="_blank">Find out more<span class="ext"><span
                class="element-invisible"> (link is external)</span></span></a></div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ExternalServices"
}
</script>

<style scoped>

</style>
