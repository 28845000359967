<template>
  <div>
    <b-container>
      <b-row class="text-center">
        <b-col>
          <h1>Log In</h1>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col class="text-center">
          <form class="login" @submit.prevent="login">
            <label>
              Email
              <b-form-input required v-model="email" type="email" placeholder="Email"/>
            </label>
            <br>
            <label>
              Password
              <b-form-input required v-model="password" type="password" placeholder="Password"/>
            </label>
            <br/>
            <b-button type="submit">Login</b-button>
            <p> Or Create a New Account</p>
            <b-button to="/register">Create an Account</b-button>
          </form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: ""
    }
  },
  methods: {
    login: function () {
      let email = this.email
      let password = this.password
      this.$store.dispatch('login', {email, password})
          .then(() => this.$router.push('/'))
          .catch(err => console.log(err))
    }
  }
}
</script>

<style scoped>

</style>
