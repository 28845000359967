<template>
  <b-container fluid="" class="text-center">
    <b-row>
      <b-col>
        <h1>
          We Offer Many Different Services, Please Select the one below that best fits your request
        </h1>
      </b-col>
    </b-row>
    <b-row cols="2" align-v="center">
      <b-col>
        <h1>
          <router-link to="/services/classes">
            Click Here to view our class offerings
          </router-link>
        </h1>
      </b-col>
      <b-col>
        <b-img src="@/assets/mche-lee-PC91Jm1DlWA-unsplash.jpg" fluid></b-img>
      </b-col>
    </b-row>
    <b-row cols="2" align-v="center">
      <b-col>
        <b-img src="@/assets/omar-rodriguez-XYuNwlYtfLI-unsplash.jpg" fluid></b-img>
      </b-col>
      <b-col>
        <h1>
          <router-link to="/services/reservation">
            Click Here for Facilities Reservations
          </router-link>
        </h1>
      </b-col>
    </b-row>
    <b-row cols="2" align-v="center">
      <b-col>
        <h1>
          <router-link to="/services/tools">
            Click Here for Equipment Reservations
          </router-link>
        </h1>
      </b-col>
      <b-col>
        <b-img src="@/assets/barn-images-t5YUoHW6zRo-unsplash.jpg" fluid></b-img>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Services"
}
</script>

<style scoped>

</style>
